<template>
  <div class="admin-create-user-import">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">
        New Import for {{ selectedOrganisation.displayName }}
      </h1>
      <div class="actions">
        <a-button class="button-margin-left btn-rounded" icon="arrow-left" size="large" type="primary"
          @click.prevent="goBack">Back</a-button>
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Download excel -->
    <div class="form">
      <div class="form-flex">
        <div class="left">
          <p>
            To upload users, please download and fill in the user import
            spreadsheet
          </p>
          <p style="margin-bottom: 0 !important">
            New users will be created and existing users will be updated by
            matching the email address. Email invites are not sent out to new
            users until you choose to send them.
          </p>
        </div>
        <div class="right">
          <a-button @click.prevent="downloadSpreadsheet" icon="cloud-download" type="primary">Download Spreadsheet
          </a-button>
        </div>
      </div>
    </div>
    <!-- / Download excel -->

    <a-upload-dragger name="file" :multiple="false" :file-list="fileList" :remove="handleRemove"
      :before-upload="beforeUpload">
      <p class="ant-upload-drag-icon">
        <a-icon type="inbox" />
      </p>
      <p class="ant-upload-text">
        Click or drag file to this area to start import
      </p>
      <p class="ant-upload-hint">
        Please download and fill in the spreadsheet from above and upload here
      </p>
    </a-upload-dragger>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
import userImports from "../../../../api/user-imports";
export default {
  components: { LoadingScreen },
  data() {
    return {
      isSaving: false,

      fileList: [],
    };
  },
  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },
  computed: {
    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
    }),
  },
  methods: {
    goBack() {
      return this.$router.push("/admin/users/imports");
    },

    downloadSpreadsheet() {
      window.open("https://tsos.at/user-spreadsheet", "_blank");
    },

    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      this.fileList = [file];
      this.attemptImport();
      return false;
    },

    attemptImport() {
      let vm = this;
      vm.isSaving = true;

      let params = {
        importType: 1,
        ownerId: this.selectedOrganisation.id
      };
      console.log('Params are');
      console.log(params);
      const paramsJson = JSON.stringify(params);
      const userImportObjectBlob = new Blob([paramsJson]);
      const userImportObjectFile = new File([userImportObjectBlob], "userimportobject.json");

      console.log('user import object');
      console.log(userImportObjectFile);

      userImports.addUserImport(
        this.tenantId,
        userImportObjectFile,
        this.fileList[0]
      ).then(r => {
        console.log(r);
        this.$router.push('/admin/users/imports?imported=true');
      }).catch(e => {
        console.log(e);
        vm.isSaving = false;
        vm.fileList = [];
        vm.$message.error('Error importing');
      });
    }
  },
};
</script>

<style scoped lang="scss">
.form {
  background: #fff;
  padding: 20px;
  border-radius: 6px;
  font-weight: 500;
  border: 1px solid #fff;
  margin-bottom: 20px;
}

.form-flex {
  display: flex;

  .left {
    flex-grow: 1;
  }

  .right {
    padding-left: 20px;
    flex-shrink: 1;
    display: flex;
    align-items: center;
  }
}
</style>